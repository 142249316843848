import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div className="privacy-container">
      <h2>Contact Us</h2>
      <p>
        EasypdfPrp file converter tool is a handy extension that allows you to
        convert files from one format to another. It is designed for you when
        you need to move your files to a new format.
      </p>
      <h4>HeadQuarters:</h4>
      <address>
        Finsoft Management FZE B.C. 1304379 Ajman Free Zone Ajman, UAE
      </address>
      <h4>Corporate office:</h4>
      <address>
        Finsoft Management FZE (Teqfire) 23rd floor, The Tower Plaza Sheikh
        Zayed Road Dubai, UAE
      </address>
      <p>Email: <a href="mailto:info@dailydosequote.com">info@dailydosequote.com</a></p>
    </div>
  );
};

export default ContactUs;
