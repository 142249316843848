export const FILE_EXTENSIONS = [
  {
    id: "pdf",
    label: "PDF",
  },
  {
    id: "doc",
    label: "DOC",
  },
  {
    id: "docx",
    label: "DOCX",
  },
  {
    id: "pptx",
    label: "PPTX",
  },
  {
    id: "ppt",
    label: "PPT",
  },
];
// pdf: png, jpg, tiff, txt, docx, pptx,
// doc: pdf, docx, txt
export const TO_CONVERT = {
  pdf: [
    {
      id: "png",
      label: "PNG",
    },
    {
      id: "jpg",
      label: "JPG",
    },
    {
      id: "tiff",
      label: "TIFF",
    },
    {
      id: "txt",
      label: "TEXT",
    },
    {
      id: "docx",
      label: "DOCX",
    },
    {
      id: "pptx",
      label: "PPTX",
    },
  ],
  doc: [
    {
      id: "pdf",
      label: "PDF",
    },
    {
      id: "txt",
      label: "TEXT",
    },
    {
      id: "docx",
      label: "DOCX",
    },
  ],
  docx: [
    {
      id: "pdf",
      label: "PDF",
    },
    {
      id: "txt",
      label: "TEXT",
    },
    {
      id: "rtf",
      label: "RTF",
    },
    {
      id: "png",
      label: "PNG",
    },
    {
      id: "jpg",
      label: "JPG",
    },
    {
      id: "html",
      label: "HTML",
    },
    {
      id: "doc",
      label: "DOC",
    },
  ],
  pptx: [
    {
      id: "pdf",
      label: "PDF",
    },
    {
      id: "txt",
      label: "TEXT",
    },
    {
      id: "ppt",
      label: "PPT",
    },
    {
      id: "png",
      label: "PNG",
    },
  ],
  ppt: [
    {
      id: "pdf",
      label: "PDF",
    },
    {
      id: "pptx",
      label: "PPTX",
    },
  ],
};

export const API_URL = "https://api.cloudmersive.com/";
