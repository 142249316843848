import PropTypes from "prop-types";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./Footer.css";

const Footer = (props) => {
  return (
    <div className="footer-links">
      <div>
        <Link to="/">Home</Link>
      </div>
      <div>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <div>
        <Link to="/about-us">About Us</Link>
      </div>
      <div>
        <Link to="/terms">Terms and Condition</Link>
      </div>
      <div>
        <Link to="/contact-us">Contact Us</Link>
      </div>
    </div>
  );
};

Footer.propTypes = {};

export default Footer;
