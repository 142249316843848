import "./ThankYou.css";

const ThankYou = () => {
  return (
    <>
    <div className="main">
            <div className="logoSection">
                <img src="pdf-logo.svg" className="imageProperties" />
            </div>
			<div className="contentSection">
				<div className="title">Thank you!</div>
				<div className="subtitle">Your file converter is ready to use.</div>
			</div>  
		</div>
    </>
  );
};

export default ThankYou;
