import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div className="privacy-container">
      <h2>About Us</h2>
      <p>
        EasypdfPrp file converter tool is a handy extension that allows you to
        convert files from one format to another. It is designed for you when
        you need to move your files to a new format.
      </p>
      <p>
        At Teqfire Media we focus on delivering creative marketing solutions
        that drive bigger and better results, than on other major search
        engines. We ensure that the right ads are delivered to the right
        audience at the right time, thus achieving a higher click through rate,
        higher conversions. We are able to deliver the highest available search
        engine traffic outside of any of the major search engines for all online
        advertising solutions.
      </p>
    </div>
  );
};

export default AboutUs;
