import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";

import Header from './components/common/Header';
import Footer from './components/common/Footer';
import ConversionForm from './components/ConversionForm';
import Lottie from 'react-lottie-player'
import landingPage from './landingPage.json';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivacyPolicy from './components/common/PrivacyPolicy';
import AboutUs from './components/common/about-us/AboutUs';
import Terms from './components/common/terms/Terms';
import ContactUs from  './components/common/contact/ContactUs'
import ThankYou from './components/common/thank-you/ThankYou';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      {/* <Header /> */}
      {/* <div className="lottieContainer">
      <Lottie
        loop
        animationData={landingPage}
        play
        style={{ width: "70%", height: 500 }}
      />
      </div> */}
      {/* comment this line and take `npm run build` for website*/}
      {/* <ConversionForm /> */}
      {/* comment Routes and Footer then take `npm run build` for chrome  extenstion  */}
      <Routes>
        <Route exact path="/" element={<ConversionForm />} />
        <Route index element={<ConversionForm />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/thank-you" element={<ThankYou />} />
      </Routes>
      <Footer />
    </div>

    </BrowserRouter>
  );
}

export default App;
