import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Lottie from "react-lottie-player";
import loader from "../loader.json";
import { enqueueSnackbar } from "notistack";
import uniqid from "uniqid";
import { FILE_EXTENSIONS, TO_CONVERT } from "../constants";

const ConversionForm = (props) => {
  const formData = new FormData();
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [from, setFrom] = useState("doc");
  const [to, setTo] = useState("pdf");

  useEffect(() => {
    setTo(TO_CONVERT[from][0].id);
  }, [from]);

  const onChangeOfFrom = (event) => {
    setFrom(event.target.value);
  };

  const onChangeOfTo = (event) => {
    setTo(event.target.value);
  };

  const API_KEY = "6ccdd6d8-b970-48f9-9f8e-ce16110a2a8c";
  const config = {
    responseType: "blob",
    headers: {
      Apikey: API_KEY,
    },
  };

  const onChangeOf = (event) => {
    if (event.target.files.length > 0) {
      formData.append("inputFile", event.target.files[0], "file");
      convertDoc(formData);
    }
  };

  function saveBlobAsFile(blobData, fileName) {
    const url = URL.createObjectURL(blobData);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    // Trigger a click event to download the file
    a.click();

    // Clean up
    URL.revokeObjectURL(url);
    setIsApiLoading(false);
    formData.delete("inputFile");
  }

  const convertDoc = (formData) => {
    const url = `https://api.cloudmersive.com/convert/${from}/to/${to}`;
    setIsApiLoading(true);
    axios
      .post(url, formData, config)
      .then((res) => {
        // Call a function to save the blob as a file
        saveBlobAsFile(res.data, `output-${uniqid()}.${to}`);
        const message = "Success. Please check your downloads.";
        enqueueSnackbar(message, {
          variant: "success",
          autoHideDuration: 1000,
        });
      })
      .catch((err) => {
        console.log(err);
        const message = "Something went wrong!. Please try again later.";
        enqueueSnackbar(message, { variant: "error", autoHideDuration: 1000 });
        setIsApiLoading(false);
      });
  };

  return (
    <form>
      <img src="pdf-logo.svg" />
      <div className="fileUpload">
        <h3 style={{ textTransform: "uppercase" }}>Convert files</h3>
        <div className="convert-container">
          <div className="input-container">
            <div className="label-container">
              <label for="from"  className="input-label">File Convert from:</label>
            </div>
            <select
              name="from"
              id="from"
              defaultValue={from}
              onChange={onChangeOfFrom}
            >
              {FILE_EXTENSIONS.map((exts) => (
                <option
                  value={exts.id}
                  key={exts.id}
                  selected={exts.id === from}
                >
                  {exts?.label}
                </option>
              ))}
            </select>
          </div>
          <div className="input-container">
            <div className="label-container">
              <label for="to" className="input-label">File Convert to:</label>
            </div>
            <select name="to" id="to" defaultValue={to} onChange={onChangeOfTo}>
              {TO_CONVERT[from].map((exts) => (
                <option value={exts.id} key={exts.id} selected={exts.id === to}>
                  {exts?.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label for="file-input" className="input-label">Choose a Doc File</label>
            <input
              id="file-input"
              type="file"
              name="inputFile"
              accept={`.${from.toLowerCase()}`}
              disabled={isApiLoading}
              onChange={onChangeOf}
            />
          </div>
        </div>
        {isApiLoading && (
          <Lottie
            loop
            animationData={loader}
            play
            style={{ width: 150, height: 150 }}
          />
        )}
      </div>
    </form>
  );
};

ConversionForm.propTypes = {};

export default ConversionForm;
