import "./Privacy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h2>Privacy Policy</h2>
      <p>
        Your privacy is very important to us, this extension is owned and
        operated by Finsoft Management FZE, hereafter referred to as Teqfire.
        This privacy policy applies to our browser extension Easypdf and other
        sites we own, and our API.
      </p>
      <p>
        The information we gather or process is solely for improving the quality
        and security of our services. Your information isn't and has never been
        sold to third parties.
      </p>
      <h3>Below how we collect the data</h3>
      <p>
        We only ask for personal information when we truly need it to provide a
        service to you. We collect it by fair and lawful means, with your
        knowledge and consent. We also let you know why we’re collecting it and
        how it will be used. We only retain collected information for as long as
        necessary to provide you with your requested service. What data we
        store, we’ll protect within commercially acceptable means to prevent
        loss and theft, as well as unauthorised access, disclosure, copying, use
        or modification. We don’t share any personally identifying information
        publicly or with third-parties, except when required to by law. Our
        website may link to external sites that are not operated by us. Please
        be aware that we have no control over the content and practices of these
        sites, and cannot accept responsibility or liability for their
        respective privacy policies.
      </p>
      <h3>Website</h3>
      <p>
        We do not track or analyse your access or use of this website in any
        manner. No cookies are placed on your computer during your use of this
        website.
      </p>
      <p>
        This data does not reveal your specific identity (like your name or
        contact information) but may include usage information, such as country,
        location, information about how and when you use our API and other
        technical information.
      </p>
      <h3>Cookies and advertisements</h3>
      <p>
        At the moment we do not store cookies or allow third-party advertising
        companies to serve ads through our services
      </p>
      <h3>Consent</h3>
      <p>
        By using our website and services, you here by consent to our Privacy
        Policy and agree to its Terms and Conditions.
      </p>
      <p>
        If you have any questions or need more information about our privacy
        policy, please contact us at info@teqfire.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;
